import * as Sentry from '@sentry/nextjs'
import { Button, Flex, Text, Link, useColorMode } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import NextLink from 'next/link'
import { parseCookies, setCookie } from 'nookies'
import { useRouter } from 'next/router'

export default function AlertCookies() {
  const { colorMode } = useColorMode()
  const router = useRouter()
  const [exist, setExist] = useState(false)

  const handleSetCookies = () => {
    setCookie(undefined, '__gptmax_cookies', `${new Date().toString()}`, {
      maxAge: 60 * 60 * 24 * 7, //7 dias
      path: '/'
    })
    setExist(false)
  }

  useEffect(() => {
    try {
      const { __gptmax_cookies } = parseCookies()
      if (!__gptmax_cookies) {
        setExist(true)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  if (!exist || router.asPath === '/settings') {
    return null
  }

  return (
    <Flex
      className={'fade-in-image'}
      as={'article'}
      position={'absolute'}
      right={['20px', '32px']}
      bottom={['20px', '32px']}
      zIndex={99}
      bgColor={`${colorMode}.surface.main`}
      p={['32px']}
      w={'100%'}
      maxW={[320, 420]}
      borderRadius={'6px'}
      flexDirection={'column'}
      gap={'24px'}
      boxShadow={'#000 2px 4px 10px 1px'}
      align={'flex-start'}
    >
      <Text
        as={'h1'}
        fontWeight={700}
        fontSize={'lg'}
        color={`${colorMode}.surface.onSurface`}
      >
        Uso de Cookies
      </Text>
      <Text color={`${colorMode}.black`}>
        Utilizamos cookies e tecnologias afins com o objetivo de otimizar a sua
        experiência, customizar anúncios e sugerir conteúdos de acordo com o seu
        interesse, conheça nossas{' '}
        <Link
          as={NextLink}
          href="/cookies"
          color={`${colorMode}.primary.main`}
          textDecoration={'underline'}
        >
          políticas de cookies
        </Link>
        .
      </Text>

      <Button
        color={`${colorMode}.primary.onPrimary`}
        size={'sm'}
        _hover={{
          bgColor: `${colorMode}.primary.mainHover`
        }}
        onClick={handleSetCookies}
      >
        Prosseguir com a navegação
      </Button>
    </Flex>
  )
}

import { ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { signOut, useSession } from 'next-auth/react'

import SplashScreen from '@/component/SplashScreen'
import { PermissionCode } from '@/interfaces/Permission'

type PageAuthProps = {
  isProtected: boolean
  permissionCode: PermissionCode
}
interface Props {
  children: ReactNode
  auth?: PageAuthProps
}

export default function AuthDashboard({ children, auth }: Props) {
  const router = useRouter()
  const [isChecked, setIsChecked] = useState(false)

  const { status, data } = useSession({
    required: true,
    onUnauthenticated: async () => {
      await signOut({
        redirect: false
      })
      await router.replace('/login')
    }
  })

  useEffect(() => {
    if (status === 'authenticated' && data.user && auth && !data?.error) {
      const permissions = data?.user?.permissions
      if (!permissions) {
        ;(async () => {
          await signOut({
            redirect: false
          })
          await router.replace('/login')
        })()
      }

      const isAuthorized = data?.user?.permissions?.includes(
        auth.permissionCode
      )
      if (!isAuthorized) {
        router.push('/unauthorized')
      } else {
        setIsChecked(true)
      }
    }

    if (data?.error === 'RefreshTokenError') {
      ;(async () => {
        await signOut({
          redirect: false
        })
        await router.replace('/login')
      })()
    }
  }, [status, data])

  if (status === 'loading') {
    return <SplashScreen text={'Verificando acesso...'} />
  }
  return isChecked ? <>{children}</> : null
}

import {
  defineStyleConfig,
  createMultiStyleConfigHelpers
} from '@chakra-ui/react'
import { tabsAnatomy } from '@chakra-ui/anatomy'

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '6px'
  },
  sizes: {
    sm: {
      height: '32px'
    },
    md: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px'
    },
    xl: {
      fontSize: '24px',
      h: '66px',
      pl: '24px',
      py: '16px'
    }
  },
  variants: {
    solid: ({ colorMode, theme }) => ({
      bgColor: theme.colors[colorMode].primary.main,
      color: theme.colors[colorMode].primary.onPrimary,
      _hover: {
        bgColor: theme.colors[colorMode].primary.mainHover,
        _disabled: {
          bgColor: theme.colors[colorMode].primary.main
        }
      },
      _active: {
        bgColor: theme.colors[colorMode].primary.main
      }
    }),
    outline: ({ colorMode, theme }) => ({
      bgColor: 'transparent',
      color: theme.colors[colorMode].primary.main,
      borderColor: theme.colors[colorMode].primary.main,
      _hover: {
        bgColor: theme.colors[colorMode].primary.main,
        color: theme.colors[colorMode].primary.onPrimary
      },
      _active: {
        bgColor: 'transparent',
        color: theme.colors[colorMode].primary.main
      },
      _disabled: {
        color: theme.colors[colorMode].primary.main,
        _hover: {
          color: theme.colors[colorMode].primary.main
        }
      }
    }),
    link: ({ colorMode, theme }) => ({
      color: theme.colors[colorMode].primary.main
    }),
    'primary-gray': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      gap: '8px',
      fontWeight: '500',
      fontSize: '12px',
      color: '#19213D',
      px: '14px',
      _hover: {
        bgColor: '#fff',
        boxShadow: '0px 1px 3px 0px #19213D1A'
      },
      _disabled: {
        bgColor: 'transparent',
        boxShadow: 'none'
      }
    },
    'secondary-gray': ({ colorMode, theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      gap: '8px',
      fontWeight: '500',
      fontSize: '12px',
      color: '#19213D',
      px: '14px',
      bgColor: theme.colors[colorMode].surface.containerLowest,
      _hover: {
        boxShadow: '0px 1px 3px 0px #19213D1A'
      },
      _disabled: {
        boxShadow: 'none'
      }
    })
  }
})

export const Spinner = defineStyleConfig({
  baseStyle: ({ colorMode, theme }) => ({
    color: theme.colors[colorMode].primary.main
  })
})

export const Text = defineStyleConfig({
  baseStyle: ({ colorMode, theme }) => ({
    color: theme.colors[colorMode].primary.main
  })
})

const stepperHelpers = createMultiStyleConfigHelpers([
  'indicator',
  'separator',
  'title'
])
export const Stepper = stepperHelpers.defineMultiStyleConfig({
  baseStyle: ({ colorMode, theme }) => ({
    indicator: {
      width: '3rem',
      height: '3rem',
      borderColor: theme.colors[colorMode].primary.main,
      color: theme.colors[colorMode].primary.main,
      fontWeight: 'semibold',
      cursor: 'pointer',
      '[data-status=active] &': {
        background: theme.colors[colorMode].primary.container
      },
      '[data-status=complete] &': {
        background: theme.colors[colorMode].primary.container,
        color: theme.colors[colorMode].primary.main
      }
    },
    separator: {
      background: theme.colors[colorMode].primary.main
    },
    title: {
      color: theme.colors[colorMode].primary.main,
      fontWeight: 'semibold'
    }
  })
})

const tabsHelpers = createMultiStyleConfigHelpers(tabsAnatomy.keys)
const solidRounded = tabsHelpers.definePartsStyle(({ colorMode, theme }) => {
  return {
    tab: {
      fontSize: 'sm',
      fontWeight: 'normal',
      borderRadius: '6px',
      borderWidth: '1px',
      borderColor: theme.colors[colorMode].primary.main,
      color: theme.colors[colorMode].primary.main,
      whiteSpace: 'nowrap',
      _selected: {
        bgColor: theme.colors[colorMode].primary.container
      }
    },
    tablist: {
      gap: '12px',
      flexWrap: 'wrap'
    }
  }
})
export const Tabs = tabsHelpers.defineMultiStyleConfig({
  variants: {
    'solid-rounded': solidRounded
  }
})

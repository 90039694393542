import * as Sentry from '@sentry/nextjs'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react'
import get from 'lodash.get'
import { useSession } from 'next-auth/react'
import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { parseCookies, setCookie } from 'nookies'
import { useRouter } from 'next/router'

const NotificationContext = createContext({})

const NOT_VISIBLE_PATHS = ['/', '/login']

export function NotificationContextProvider({ children }: PropsWithChildren) {
  const { pathname } = useRouter()
  const { colorMode } = useColorMode()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { data: sessionData } = useSession()
  const userTenant = get(sessionData, 'user.tenantId', null)

  const handleClose = () => {
    setCookie(undefined, '__gptmax_notification', `${new Date().toString()}`, {
      maxAge: 60 * 60 * 24 * 30, //7 dias
      path: '/'
    })
    onClose()
  }

  useEffect(() => {
    try {
      const { __gptmax_notification } = parseCookies()
      if (
        !__gptmax_notification &&
        userTenant === 14 &&
        !NOT_VISIBLE_PATHS.includes(pathname)
      ) {
        onOpen()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  return (
    <NotificationContext.Provider value={{}}>
      {children}
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(2px)" />
        <ModalContent bgColor={`${colorMode}.surface.main`}>
          <ModalHeader
            color={`${colorMode}.primary.main`}
            textStyle={'large'}
            fontSize={'16px'}
          >
            Aviso
          </ModalHeader>

          <ModalBody>
            <Text>
              Informamos que o plano Master da plataforma GPT Max será
              descontinuado a partir do dia 15/03/2025. Para esclarecimentos
              sobre esta situação e informações adicionais, solicitamos que
              entre em contato com nossa equipe de suporte.
            </Text>
            <Text fontWeight={'600'} py={4}>
              Suporte GPT Max: atendimento@dankicode.ai
            </Text>
            <Text>Agradecemos sua compreensão.</Text>
            <Text fontWeight={'500'}>Equipe Danki Code AI</Text>

            <Flex justifyContent="flex-end">
              <Button onClick={handleClose}>Fechar</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)

import { ImageStyles } from '@/component/ImageStudio/ModalImageStyle'
import { ImageStudioTool } from '@/component/ImageStudio/utils'
import { GalleryImage } from '@/interfaces/Images'
import { api } from '@/services/api'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'

interface ImageGenerateProviderProps {
  children: ReactNode
}

type ImageGenerateContextData = {
  prompt: string
  setPrompt: (s: string) => void
  selectedStyles: ImageStyles[]
  setSelectedStyles: Dispatch<SetStateAction<ImageStyles[]>>
  selectStyleNames: string | undefined
  selectedImage: GalleryImage | null
  setSelectedImage: (image: GalleryImage | null) => void
  tool: ImageStudioTool | null
  handleMotion: (selectedImagePath: string) => Promise<any>
  handleToolClick: (newTool: ImageStudioTool | null) => void
}

const ImageGenerateContext = createContext({} as ImageGenerateContextData)

export function ImageGenerateProvider({
  children
}: ImageGenerateProviderProps) {
  const [prompt, setPrompt] = useState('')
  const [selectedStyles, setSelectedStyles] = useState<ImageStyles[]>([])
  const selectStyleNames = selectedStyles.map((p) => p.name).join()
  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null)
  const [tool, setTool] = useState<ImageStudioTool | null>(null)

  const handleToolClick = (newTool: ImageStudioTool | null) => {
    setTool(newTool)
  }

  const handleMotion = async (selectedImagePath: string) => {
    try {
      const response = await api.post('/api/images/motion', {
        path: selectedImagePath
      })
      return response.data
    } catch (error) {
      console.error('Erro ao transformar imagem em vídeo:', error)
      throw error
    }
  }

  return (
    <ImageGenerateContext.Provider
      value={{
        prompt,
        setPrompt,
        selectedStyles,
        selectStyleNames,
        setSelectedStyles,
        selectedImage,
        setSelectedImage,
        tool,
        handleMotion,
        handleToolClick
      }}
    >
      {children}
    </ImageGenerateContext.Provider>
  )
}

export const useImageContext = () => {
  const context = useContext(ImageGenerateContext)
  if (context === undefined) {
    throw new Error('useImage must be used within an ImageProvider')
  }
  return context
}

import { createContext, ReactNode, useContext } from 'react'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks'

interface ISidebarDrawerProviderProps {
  children: ReactNode
}

type SidebarDrawerContextData = {
  compact: UseDisclosureReturn
  drawer: UseDisclosureReturn
}

const SidebarDrawerContext = createContext({} as SidebarDrawerContextData)

export function SidebarDrawerProvider({
  children
}: ISidebarDrawerProviderProps) {
  const disclosureDrawer = useDisclosure({ defaultIsOpen: false })
  const disclosureCompact = useDisclosure({ defaultIsOpen: true })

  return (
    <SidebarDrawerContext.Provider
      value={{
        compact: disclosureCompact,
        drawer: disclosureDrawer
      }}
    >
      {children}
    </SidebarDrawerContext.Provider>
  )
}

export const useSidebarDrawer = () => useContext(SidebarDrawerContext)
